body {
  background-color: black;
}
.gif-container div {
  max-width: 100%;
  aspect-ratio: 16/9;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.gif-container div img {
  width: 100%;
  height: 100%;
}
.gif-container {
  position: relative;
  margin-bottom:2rem;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr;
  gap: 5px 5px;
  grid-auto-flow: row;
  grid-template-areas:
    "gif-container-1 gif-container-1 gif-container-2 gif-container-3"
    "gif-container-1 gif-container-1 gif-container-4 gif-container-5"
    "gif-container-6 gif-container-8 gif-container-8 gif-container-9"
    "gif-container-7 gif-container-8 gif-container-8 gif-container-10";
}

.gif-container-1 {
  grid-area: gif-container-1;
}

.gif-container-2 {
  grid-area: gif-container-2;
}

.gif-container-3 {
  grid-area: gif-container-3;
}

.gif-container-4 {
  grid-area: gif-container-4;
}

.gif-container-5 {
  grid-area: gif-container-5;
}

.gif-container-6 {
  grid-area: gif-container-6;
}

.gif-container-7 {
  grid-area: gif-container-7;
}

.gif-container-8 {
  grid-area: gif-container-8;
}

.gif-container-9 {
  grid-area: gif-container-9;
}

.gif-container-10 {
  grid-area: gif-container-10;
}
.gif-label{
  width:100%;
  height:3rem;
  font-size: 1.2rem;
  text-transform: uppercase;
  letter-spacing:1px;
  position:absolute;
  bottom:-4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Open Sans';
  color:white;
  background-color:rgb(65, 58, 58);
  z-index: 500;
  overflow:hidden;
  transition: bottom 0.2s ease-in-out;
  visibility:hidden;
}
.gif-container div:hover .gif-label{
    visibility:visible;
    bottom:-1rem;
    /* transform:translateY(-3.5rem); */
}
@media (max-width: 530px) {
  .gif-container {
    row-gap: 1rem;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-areas:
        "gif-container-1"
        "gif-container-2"
        "gif-container-3"
        "gif-container-4"
        "gif-container-5"
        "gif-container-6"
        "gif-container-7"
        "gif-container-8"
        "gif-container-9"
        "gif-container-10";
  }
  .gif-container div {
    cursor: pointer;
  }
}
