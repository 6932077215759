@import url("https://fonts.googleapis.com/css2?family=Open+Sans&family=Source+Code+Pro:wght@400&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;1,300&display=swap');


.body {
    padding: 20px;
    background-color: rgb(14, 11, 11);
    font-family: "Open Sans", sans-serif;
}

.div{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content:center;
    align-items:center;
}
.h1{
    color: white;
    font-size:3rem;
    text-align:center;
    margin-bottom:2rem;
    letter-spacing:5px;
}
iframe {
    min-width: 80%;
    border-radius: 10px;
    aspect-ratio: 16/9;
    border: 1px solid black;
    box-shadow: 0px 1rem 5rem rgb(74, 137, 127),0px 1rem 5rem rgb(61, 94, 89);
}
@media(max-width:767px){
    iframe {
        width: 100%;
    }
}