@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap");
html{
  font-size:62.8%;
}
.footer-container {

  font-family: "Open Sans", sans-serif;
  color: rgb(236, 231, 231);
  background-color: black;
  color:white;
  display:flex;
  flex-direction: column;
  justify-content:space-between;
  margin-top:2rem;
}
.footer-container-2 {
  grid-area: footer-container-2;
  text-align: center;
  margin-bottom:1.7rem;
}
.footer-container-2 h2,
.footer-container-2 h3 {
  letter-spacing: 2px;
}
.footer-container-2 h2 {
  font-size: 1.5rem;
}
.footer-container-2 h3 {
  font-size: 1.5rem;
}
.footer-container-3 {
  margin-bottom:2rem;
  grid-area: footer-container-3;
  text-align: center;
}
.footer-container-3 h4 {
  font-size: 1.5rem;
}
.footer-container-3 h4 i {
  margin-right: 5px;
  color: skyblue;
}
.footer-container-3 h4 a {
  text-decoration: none;
  color: white;
}
.footer-container-4 {
  grid-area: footer-container-4;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top:2rem;
}
.footer-container-4 div {
  margin-right: 2rem;
}
.footer-container-4 i {
  cursor: pointer;
  transition: ease-in-out 0.5s;
  color: rgb(189, 176, 176);
}
.footer-container i:hover {
  transform: translateZ(90px);
  color: white;
}
.scroll-anim i {
  cursor: pointer;
  transition: ease-in-out 0.5s;
  color: rgb(189, 176, 176);
}
.scroll-anim {
  overflow: hidden;
  width: 50px;
  height: 55px;
  transition: all 0.6s ease-in-out;
  display: block;
  text-align: center;
}
.scroll-anim .i-1 {
  width: 50px;
  height: 60px;
}
.scroll-anim .i-2 {
  width: 50px;
  height: 60px;
}
.scroll-anim:hover .i-1 {
  transform: translateY(-60px);
}
.scroll-anim:hover .i-2 {
  transform: translateY(-60px);
}
